@import '../../styles/variables';
@import '../../styles/mixins';
.popup-class {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  box-shadow: 0 0 30px 0 #444;
  padding: 50px 40px;
  position: relative;
  .swal2-html-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    line-height: 1.4em;
  }
}

.title-class {
  font-size: 28px !important;
  padding: 0;
  font-family: $fontTitleItalic !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  // font-style: italic;
  color: $primary_color;
  /* max-width: 360px !important; */
  margin: auto !important;
  text-transform: uppercase;
}

.confirm-button-class {
  @include pinkButton;
}

.html-class {
  color: #000 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-right: 30px !important;
  /* font-family: 'RobotoRegular' !important; */
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: $fontDescription !important;
}

.close-button-class {
  color: #fff !important;
  font-size: 1.5em;
  box-shadow: none !important;
  background: $LoginActionColor !important;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  position: absolute;
  right: 16px;
  top: 16px;
  line-height: 19px;
}

.cancel-button-class {
  font-family: 'UFCSans-Medium' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border: none !important;
  width: 200px !important;
  height: 52px !important;
  background: #ffffff !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: unset !important;
  text-transform: uppercase !important;
  border: 2px solid #d20a0a !important;
  transition: 0.4s ease-in-out;
  color: #d20a0a !important;
}
.cancel-button-class:hover {
  color: #ffffff !important;
  background: linear-gradient(216.01deg, #d20a0a 26.18%, #9e0808 93.27%) !important;
  transition: 0.4s ease-in-out !important;
}
