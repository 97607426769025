@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@import './variables';

@mixin ButtonOrange {
  .actionButton {
    border-radius: 100px;
    background: $primary_orange;
    box-shadow: $boxShadow;
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 50px;
    width: 192.48px;
    outline: 0;
    border: 0;
    transition: all 0.3s;
    &:hover {
      background: $primary_color_Blue;
    }
  }
}

@mixin primaryButton {
  border-radius: 100px;
  font-family: $fontDescription;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.3s;
  text-transform: capitalize;
  padding: 16px 34px;
  background-color: $LoginActionColor;
  border: none;
  color: #fff;
  &:hover {
    background: $primary_color !important;
    color: #fff;
  }
}

@mixin pinkButton {
  border-radius: 30px !important;
  font-family: $fontDescription;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.3s;
  text-transform: capitalize;
  padding: 14px 34px;
  color: #fff;
  background-color: $LoginActionColor !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  &:hover {
    background: $primary_color !important;
    color: #fff;
  }
}
