* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

@import './styles/variables';
@import './styles/mixins';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@font-face {
  font-family: 'Simply Cricket';
  src: url("./fonts/cricket/SimplyCricket.ttf") format('truetype');
}


.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

.swal2-container {
  z-index: 999999999;
}

/*================ global-css-for-dashboard-tables ================*/
.search-box {
  form {
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px #ebebeb;
    padding: 3px 5px 3px 15px;
  }
}

.table-bordered {
  background: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 30px 0 #ebebeb;
  font-family: 'Roboto';

  thead {
    tr {
      th {
        background: $primary_color;
        color: #fff;
        border: none;
        padding: 18px 0px 18px 20px;
        font-weight: 500;

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }

  tbody {
    tr {
      border-color: #dadada;

      td {
        border: none;
        padding: 18px 0px 18px 20px;
        font-size: 15px;
        font-family: 'Roboto';

        &:nth-last-child(2) {
          button {
            white-space: nowrap;
          }
        }

        &:last-child {
          white-space: nowrap;
          display: flex;
          gap: 6px;
          padding-right: 13px;

          button:nth-child(1) {
            background: #f15f3d;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;

            svg {
              width: 0.8em;
              height: 0.8em;

              path {
                fill: #fff;
              }
            }
          }

          button:nth-child(2) {
            background: #f3c42b;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;

            svg {
              width: 0.8em;
              height: 0.8em;
            }
          }

          button:nth-child(3) {
            background: #0c4ccf;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;

            svg {
              width: 0.8em;
              height: 0.8em;
            }
          }
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.MuiButton-outlinedPrimary {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.MuiPagination-ul .Mui-selected {
  background-color: #f7acac !important;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.MuiPaginationItem-page {
  font-family: 'Roboto' !important;
}

/*=============== /global-css-for-dashboard-tables ================*/

.list-box .react-multiple-carousel__arrow {
  top: 65px;
}

.list-box .react-multi-carousel-dot button {
  width: 32.03px;
  height: 7px;
  border-radius: 0;
  border-width: 0;
}

.list-box .react-multi-carousel-dot--active button {
  background-color: #70A443;
}


// safari css
.safari .prize-list-wrapper .box-wrap .list-box h1 {
  font-weight: 600 !important;
}

.safari .prize-list-wrapper .box-wrap .list-box .prizeTiles .content .contents .title,
.safari .prize-list-wrapper .box-wrap .list-box .prizeTitle {
  font-weight: 500 !important;
}

.safari .prize-list-wrapper .box-wrap .list-box .description,
.safari .prize-list-wrapper .box-wrap .list-box .prizeTiles .content .contents .prize-description {
  font-weight: 500 !important;
}

.safari .prize-list-wrapper .prizeTilesnext .content .contents .title {
  font-weight: 500 !important;
}

.safari .prize-list-wrapper .prizeTilesnext .content .contents .prize-description {
  font-weight: 500 !important;
}

.safari .cricket-confirmation .thankyou-section .inner-box .content .icons-section .icons p,
.safari .cricket-confirmation .thankyou-section .inner-box .content .disclaimer-content p span,
.safari .cricket-confirmation .thankyou-section .inner-box .content strong,
.safari .cricket-confirmation .thankyou-section .inner-box .content b {
  font-weight: 500 !important;
}

.safari .desktop-menu .items-menu a {
  font-weight: 500;
}

.safari .cricket-confirmation .thankyou-section .inner-box .content h1 {
  font-weight: 600;
}

.safari .prize-list-wrapper .box-wrap .list-box .entryDate {
  font-weight: 500;
}

.safari .login-wrapper-modal .signup-box .MuiFormControlLabel-label a,
.safari .cricketfooter .footermodule .footermenu li a {
  font-weight: 500;
}

.safari span,.safari strong {
  font-weight: 500;
}
.safari span sup {
  top: -0.2em;
}
.safari span a {
  color: #000;
}

