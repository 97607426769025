// Colors
$primary_color: #60A630;
$secondary_color: #f3c42b;
$white: #fff;
$textPrimary: #313738;
$LoginActionColor: #CF142E;

// font family

$fontTitle: 'Roboto', sans-serif;
$fontTitleItalic: 'Termina Test', sans-serif;
$fontDescription: 'Roboto', sans-serif;
$TTHovesThin: 'TTHoves Thin';

// Dashboard color codes
$primary_color_Blue: #60A630;
$primary_orange: #f15f3d;
$light_graybackground: #f8f8f8;

// Box Shadow

$boxShadow: 0px 0px 30px 0px #ebebeb;
