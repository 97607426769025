@import '../../styles/variables'; // Import the variable.scss file
@import '../../styles/mixins';

.main-navbar {
  padding: 15px 0 !important;

  .navbar-brand {
    img {
      max-width: 80%;
      @include media-breakpoint-down(lg){
        margin-left: 30px;
      }
    }
  }

  .cart-icon {
    position: relative;
    cursor: pointer;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      margin-right: 10px;
    }

    img {
      width: 27px;
    }

    .badge {
      color: #fff;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px;
      text-transform: uppercase;
      background-color: $LoginActionColor;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      padding: 4px;
      align-items: center;
      position: absolute;
      top: 0;
      right: -10px;
    }
  }

  .coins-wrap {
    display: flex;
    align-items: center;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      margin-right: 10px;
    }

    .coins {
      text-align: center;
      margin-right: 5px;

      @include media-breakpoint-down(md) {
        margin-right: 2px;
      }

      .credit {
        color: $textPrimary;
        font-family: $fontTitle;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

      }

      .coin-label {
        color: #33363f;
        font-family: $fontTitle;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .user-icon {
    cursor: pointer;
    display: flex;
    align-items: center;

    .user-name {
      color: $textPrimary;
      font-family: 'Simply Cricket';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-left: 3px;

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }

    img {
      width: 28px;
    }
  }

  .divider {
    background-color: #000;
    opacity: 1;
    width: 2px;
    height: 70px !important;
    align-self: center !important;
  }
}

.desktop-menu {
  .items-menu {
    a {
      font-family: "Simply Cricket";
      font-size: 24px;
      font-weight: 600;
      line-height: 34.8px;
      letter-spacing: 0.02em;
      padding: 0 20px;
      border-right: 4px solid #044A07;
      text-decoration: none;
      color: #000;
      &:last-child{
        border-right: 0;
        padding-right: 0;
      }
      @include media-breakpoint-between(lg, xl) {
        padding: 0 16px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.mobile-menu {
  .link-test {
    span {
      font-size: 16px;
      font-weight: 700;
      font-family: 'Simply Cricket';
    }
  }

  .mobileMenuItem {
    gap: 10px;

    .menu-iocn {
      min-width: auto;
    }
  }
}

.navbar-collapse {
  button {
    svg {
      fill: #000;
    }
  }
}
