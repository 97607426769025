@import '../../styles/mixins';
@import '../../styles/variables';

.cricketfooter {
    background: #404041;
    color: #fff;
    .footermodule {
        .footermenu {
            display: flex;
            justify-content: center;
            gap: 50px;
            padding: 0;
            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
                gap: 20px;
            }
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                gap: 10px;
                justify-content: flex-start;
                padding-bottom: 10px;
            }
            li {
                list-style: none;
                a {
                    color: #fff;
                    font-family: 'Simply Cricket';
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 26.1px;
                    letter-spacing: 0.02em;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }
        }
        p {
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            a {
                color: #fff;
            }
            @include media-breakpoint-down(sm) {
                text-align: left;
                font-size: 14px;
            }
        }
        .socialicon {
            display: flex;
            gap: 25px;
            justify-content: center;
            margin: 3% 0;
            li {
                list-style: none;
                transition: all ease-in-out 0.3s;
                -webkit-transition: all ease-in-out 0.3s;
                -moz-transition: all ease-in-out 0.3s;
                -ms-transition: all ease-in-out 0.3s;
                -o-transition: all ease-in-out 0.3s;
                &:hover {
                    svg {
                        transform: scale(1.3);
                        -webkit-transform: scale(1.3);
                        -moz-transform: scale(1.3);
                        -ms-transform: scale(1.3);
                        -o-transform: scale(1.3);
                    }
                }
                svg {
                    transition: all ease-in-out 0.3s;
                    -webkit-transition: all ease-in-out 0.3s;
                    -moz-transition: all ease-in-out 0.3s;
                    -ms-transition: all ease-in-out 0.3s;
                    -o-transition: all ease-in-out 0.3s;
}
            }
        }
    }
}
